<template>
  <div class="doctor-start-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>医生</Breadcrumb-item>
        <Breadcrumb-item>开始促进</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="card-container">
        <Card>
          <p slot="title">基本信息</p>
          <div class="card-box clearfix">
            <Row>
              <Col :lg="{span: 2}" :md="{span: 4}" class="tac">
                <div class="card-avatar">
                  <div class="card-img">
                    <img :src="avater" alt="" width="80px" height="80px">
                  </div>
                  <p class="card-text">头像</p>
                </div>
              </Col>
              <Col :lg="{span: 22}" :md="{span: 20}">
                <div class="card-info">
                  <Form :label-width="85">
                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>身份证号：{{id_card}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>姓名：{{name}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>性别：{{sex | sex}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>民族：{{nation}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>生日：{{birthday}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>年龄：{{age}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>建档日期：{{create_time}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>服务员工：{{staff}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>联系电话：{{phone}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>方案名称：<a :href="plan_url" target="_blank" class="clicka">{{plan_name}}</a></p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>方案状态：{{plan_status_string}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>医生：{{booster}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p><a :href="'/#/base/member/detail/' + this.memberId + '/4'" target="_blank" class="clicka">查看药物记录</a></p>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
      <div class="commom-panel-one">
        <Form class="search-form" :label-width="111">
          <Row>
            <Col span="5">
              <Form-item label="上次促进时间：">
                {{last_time}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="沟通时长：">
                <Input v-model="doneForm.continued_time" placeholder="请输入..."></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="下次促进时间：">
                <Date-picker @on-change="changeTime" :options="options" placeholder="选择日期" v-model="doneForm.next_time" :clearable="false"></Date-picker>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="客户反馈：">
                <Input v-model="doneForm.feedback" type="textarea" :rows="4" placeholder="请输入反馈内容"></Input>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="本次服务结果：">
                <Radio-group v-model="doneForm.result">
                  <Radio label="接受并调整"></Radio>
                  <Radio label="接受近期不调整"></Radio>
                  <Radio label="不接受"></Radio>
                </Radio-group>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="后期跟进重点：">
                <Input v-model="doneForm.point" type="textarea" :rows="4" placeholder="请输入后期跟进重点"></Input>
              </Form-item>
            </Col>
          </Row>
        </Form>
      </div>
      <div class="submit-box tac">
        <Button type="success" size="large" @click="submitDone()">提交</Button>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import doctorService from '@/services/doctorService';
import { sex } from '@/filters/filters';
export default {
	components: {
		'v-title': vTitle,
	},
	filters: {
		sex,
	},
	data() {
		return {
			doneForm: {
				member_id: '',
				next_time: '',
				continued_time: '',
				feedback: '',
				result: '接受并调整',
				point: '',
			},
			avater: '',
			id_card: '',
			name: '',
			sex: '',
			nation: '',
			birthday: '',
			age: '',
			create_time: '',
			staff: '',
			phone: '',
			plan_name: '',
			plan_status_string: '',
			plan_url: '',
			booster: '',
			last_time: '',
			options: {
				disabledDate(date) {
					return date && date.valueOf() < Date.now() - 86400000;
				},
			},
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	created() {
		var param = { member_id: this.memberId };
		doctorService.getStart(param).then((data) => {
			this.avater = data.head_info.user_info.avater;
			this.id_card = data.head_info.user_info.id_card;
			this.name = data.head_info.user_info.name;
			this.sex = data.head_info.user_info.sex;
			this.nation = data.head_info.user_info.nation;
			this.birthday = data.head_info.user_info.birthday;
			this.age = data.head_info.user_info.age;
			this.create_time = data.head_info.user_info.create_time;
			this.staff = data.head_info.user_info.staff;
			this.phone = data.head_info.user_info.phone;
			this.plan_name = data.head_info.plan.plan_name;
			this.plan_status_string = data.head_info.plan.plan_status_string;
			this.plan_url = data.head_info.plan.plan_url;
			this.booster = data.head_info.user_info.booster;
			this.doneForm.member_id = data.head_info.user_info.member_id;
			this.last_time = data.last_time;
			this.doneForm.next_time = data.df_next_time;
		});
	},
	methods: {
		changeTime(date) {
			this.doneForm.next_time = date;
		},
		submitDone() {
			if (this.validateFormData()) {
				doctorService.doctorDone(this.doneForm).then(() => {
					this.$router.push({ path: '/base/doctor' });
				});
			}
		},
		validateFormData() {
			if (!this.doneForm.next_time) {
				this.$Message.warning('请填写下次促进时间');
				return false;
			}
			if (this.doneForm.continued_time.length > 30) {
				this.$Message.warning('沟通时长太长，请检查');
				return false;
			}
			if (this.doneForm.continued_time.length === 0) {
				this.$Message.warning('请填写沟通时长');
				return false;
			}
			if (this.doneForm.feedback.length === 0) {
				this.$Message.warning('请填写客户反馈');
				return false;
			}
			if (this.doneForm.point.length === 0) {
				this.$Message.warning('请填写后期跟进重点');
				return false;
			}
			return true;
		},
	},
};
</script>

<style lang="css" scoped>
.doctor-start-container .card-container .ivu-card-head > p {
  color: #fff;
}
.doctor-start-container .card-container .card-box {
  position: relative;
}
.doctor-start-container .card-container .ivu-col {
  margin-bottom: 20px;
}
.doctor-start-container .card-container .card-avatar {
  padding: 30px 0;
}
.doctor-start-container .card-container .card-img img {
  border-radius: 100%;
}
.doctor-start-container .card-container .card-text {
  text-align: center;
  padding-top: 8px;
}
.doctor-start-container .card-container .card-info {
  padding-top: 30px;
}
.doctor-start-container .card-container .ivu-form-item {
  margin-bottom: 0;
}
.doctor-start-container .clicka {
  color: #ff8400;
  text-decoration: underline;
}
.doctor-start-container .submit-box {
  margin-top: 30px;
}
.doctor-start-container .ivu-btn-success {
  padding: 11px 48px;
  margin: 0 26px;
}
.doctor-start-container .add-problem {
  padding: 10px 30px;
}
.doctor-start-container .add-problem .add-btn {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding-left: 26px;
  background: url(@/assets/img/icon_add.png) no-repeat left;
}
.doctor-start-container .ivu-radio-wrapper {
  margin-right: 30px;
}
</style>
